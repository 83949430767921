
import './App.scss';
import React from 'react';

import Layout from './Layot';
function App() {

console.log("procss", process.env)
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
