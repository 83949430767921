
import React, { useEffect, useState } from "react"
import OTPInput, { ResendOTP } from '../SharedComponent/OTPInput'
import InputLabel from '@material-ui/core/InputLabel';
const renderOtpTime = ({ remainingTime, ...buttonProps }) => {
    return (
      <span {...buttonProps} className="resend-button_newBike">
        {
          remainingTime !== 0
            && `Resend OTP in ${remainingTime} sec`
        }
      </span>
    )
  }
  
  const renderButton = ({ remainingTime, ...buttonProps }) => {
    return (
      <span  className="resend-button_newBike">
      
        {
          remainingTime === 0
            && "Din’t receive OTP ? "
        }
        {
          remainingTime === 0
            && <button {...buttonProps}>Resend OTP</button> 
        }
      </span>
    )
  }
  
  const renderTime = () => React.Fragment


const OTPBox = ({onSubmitOTP, onResendOtp, isLoading, otpResponseError, autoRead}) =>{
    const [OTP, setOTP] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpFilled, setOtpFilled] = useState(false)

    useEffect(() => {
        window.scrollTo({
          top: -150,
          behavior: "instant",
        });
    
        if(autoRead){
          if ("OTPCredential" in window) {
            const ac = new AbortController();
          
            navigator.credentials
              .get({
                otp: { transport: ["sms"] },
                signal: ac.signal
              })
              .then((otp) => {
                handleOtpInput(otp.code)
                ac.abort();
              })
              .catch((err) => {
                ac.abort();
                console.log(err);
              });
          }
        }
      }, [])
    
      const handleOtpSubmit = (otpValue) => {
        if(otpValue.length===(autoRead ? 4: 3)) 
        {
          onSubmitOTP(otpValue)
        }
        else{
        if (OTP.length !== (autoRead ? 4: 3)) {
          setOtpError(true)
          return
        }
        setOtpError(false)
        onSubmitOTP(OTP)
      }
      }
    
      const handleOtpInput = otp => {
        setOtpFilled(false)
        if (otpError) {
          setOtpError(false)
        }
        setOTP(otp)
        if(otp.length === (autoRead ? 4: 3)){
          setOtpFilled(true)
          // if(device!=='desktop'){
    
            if(autoRead) onSubmitOTP(otp)
            else handleOtpSubmit(otp)
        // }
    
        }
      }
    
      const resendOneTimePass = () => {
        setOTP('')
        onResendOtp()
        setOtpFilled(false)
      }

    return(
        <div className="otp-timer-conatiner">
       <InputLabel style={{color:"#000", fontSize:"13px"}}>
       Enter OTP
        </InputLabel>
        <OTPInput
          value={OTP}
          onChange={handleOtpInput}
          autoFocus
          OTPLength={autoRead ? 4:3}
          otpType="number"
          disabled={false}
          secure
          className= {autoRead ? 'otp-input_newBike fourDigit' : 'otp-input_newBike'}
        />
        {
          otpError && <p className="otp-error">Enter Correct OTP</p>
        }
        {
          otpResponseError  && otpFilled && <p className="otp-error">{otpResponseError}</p>
        }
        {/* <ResendOTP
          renderButton={renderButton}
          renderTime={renderTime}
          className="otp-resend"
          onResendClick={resendOneTimePass}
          renderOtpTime={renderOtpTime}
        /> */}
      </div>
    )
}

export default OTPBox