import React from 'react'
import "./styles.scss"
import BikeCenterImage from "../../../assets/icon/BikeImageCenter.svg"
import ExchangeButtonImage from "../../../assets/icon/ExchangeButtonImage.svg"
import PriceCalculatorImage from "../../../assets/icon/PriceCalculatorButtonImage.svg"
import { useNavigate } from 'react-router-dom'
const NavigatorHome = () => {
    const navigate=useNavigate()
    const onclickPriceCalc=()=>{
        navigate("/priceCalculator")
    }
    const onclickExchange=()=>{
        navigate("/exchange")
    }
  return (
    <div className="homepage-middle-section">
                <h3 className="heading-title">Get Things Done</h3>
                <div className="bg-container-navigator">
                    <div className="card-container-navigator">
                        <button onClick={onclickExchange} className='nav-image-button'><img src={ExchangeButtonImage} loading="lazy" alt="exchangeImg"/></button>
                        <button onClick={onclickPriceCalc} className='nav-image-button'> <img src={PriceCalculatorImage} loading="lazy" alt="priceImg"/></button>
                    </div>
                </div>
            
                <h3 className="heading-title">How ExchangeMyBike Works</h3>
                <div className="bg-container-how">
                    <div>
                        <div className="info-card-1">
                            <h4>1</h4>
                            <div>
                                <h4>
                                    Share used Bike Details
                                </h4>
                                <p>We meet with you to learn about your business, your goals, and your target audience.</p>
                            </div>
                        </div>
                        <div className="info-card-1">
                            <h4>2</h4>
                            <div>
                                <h4>
                                    Discover new Bikes
                                </h4>
                                <p>We meet with you to learn about your business, your goals, and your target audience.</p>
                            </div>
                        </div>
                    </div>
                    <img src={BikeCenterImage} alt="bikeImg" loading="lazy"/>
                    <div>
                        <div className="info-card-1">
                            <h4>3</h4>
                            <div>
                                <h4>
                                    Get New Vehicle Delivered
                                </h4>
                                <p>We meet with you to learn about your business, your goals, and your target audience.</p>
                            </div>
                        </div>
                        <div className="info-card-1">
                            <h4>4</h4>
                            <div>
                                <h4>
                                    Make booking
                                </h4>
                                <p>We meet with you to learn about your business, your goals, and your target audience.</p>
                            </div>
                        </div>
                    </div>

                </div>
    </div>
            
  )
}

export default NavigatorHome