import React from "react";
import LogoImageFooter from "../../assets/icon/LogoImageFooter.svg"
import LinkedinIcon from "../../assets/icon/linkedInIcon.svg"
import FbIcon from "../../assets/icon/fbIcon.svg"
import YoutubeIcon from "../../assets/icon/youtubeIcon.svg"
import XIcon from "../../assets/icon/xIcon.svg"
import InstaIcon from "../../assets/icon/InstaIcon.svg"
import "./styles.scss"
const Footer = () => {

    return(
        <footer  className="bg-container-footer"> 
            <div className="bg-card-top-footer">
                <div className="exchange-column">
                    <img src={LogoImageFooter}/>
                    <p>Our vision is to provide convenience <br/> and help increase your sales business.</p>
                </div>
                <div className="middle-column">
                    <div className="about-column">
                        <h6>About</h6>
                        <p>How it works</p>
                        <p>Featured</p>
                        <p>Partnership</p>
                        <p>Bussiness Relation</p>
                    </div>
                    <div className="community-column">
                        <h6>Community</h6>
                        <p>Events</p>
                        <p>Blog</p>
                        <p>Podcast</p>
                        <p>Invite a friend</p>
                    </div>
                </div>
                <div className="social-column">
                    <h6>Socials</h6>
                    <div className="container-icon">
                        <img src={InstaIcon}/>
                        <img src={FbIcon}/>
                        <img src={YoutubeIcon}/>
                        <img src={LinkedinIcon}/>
                        <img src={XIcon}/>

                    </div>
                   
                    
                </div>
            </div>
            <hr/>
            <div className="bg-card-bottom-footer">
                <h5>©2022 MORENT. All rights reserved</h5>
                <div className="card-right">
                    <h5>Privacy & Policy</h5>
                    <h5>Terms & Condition</h5>
                </div>
                
            </div>
        </footer>
    )
}

export default Footer