import React, { Component, useState } from "react";
import Slider from "react-slick";
import './carousel.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ExchangeCarousel = ({banner}) =>  {

  const [slideDirection, setSlideDirection] = useState(false)
   const CAROUSEL_NEWBIKE_SETTINGS = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 300,
    slidesToShow: 1,
    pauseOnHover: true,
    swipeToSlide: true,
    lazyLoad: true,
    arrows:false,
    rtl:slideDirection,
    afterChange: index => {
      if(index==1)setSlideDirection(true)
      if(index==0)setSlideDirection(false)
    },
    // useTransform: false,
    customPaging: i => (
      <div
        className="buttonStyle"

      ></div>
    )
  
  }
    return (
      <div className="newBikeCarousal">
        <Slider {...CAROUSEL_NEWBIKE_SETTINGS}>
        {
                Boolean(banner.length) && banner.map(banner => (
                  <div key={banner} className="list">
                    <img src={banner} alt="Banner" className="img-fluid"  style={{width:'100%'}}/>
                  </div>
                ))
              }
        </Slider>
      </div>
    );
  }

  export default ExchangeCarousel