import React from 'react'
import "./styles.scss"
import ThankYouLottie from "../../assets/lottie/ThankyouLottie.json"
import  Lottie  from 'lottie-react';

const Thankyou = () => {
  return (
    <div className='bg-container-thank'>
        <div className="container-card-thank">
            <Lottie
                animationData={ThankYouLottie}
                loop={true}
                autoplay={true}
                style={{ width: 300 }}
            />
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis suscipit inventore at cupiditate obcaecati iusto quisquam, sint explicabo, harum molestias nostrum. Recusandae repellat deserunt aperiam, mollitia cumque nemo quisquam veritatis!</p>
        </div>
    </div>
  )
}

export default Thankyou