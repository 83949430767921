import React from "react";

const ContactInfo = ({img, label, info}) =>{

    return(
        <div className="infoContainer">
            <img  className="info-Image" src={img} alt={label}/>
            <div className="info-box">
                <span className="info-label">
                    {label}
                </span>
                <span className="info">
                    {info}
                 </span>
            </div>
        </div>
    )
}

export default ContactInfo