import React from "react";
import AboutUsImage from "../../assets/icon/AboutUsImage.svg"
import "./styles.scss"
const About = () => { 
    
    return(
        <div className="aboutPage">
            <h1 className="aboutus-header"><b>About</b> Us</h1>
            <div className="bg-container-content">
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem id eligendi, repudiandae praesentium alias voluptatibus nihil, quaerat aut dignissimos unde, cupiditate nobis modi. Sapiente doloremque sunt quaerat quae debitis minima.
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem id eligendi, repudiandae praesentium alias voluptatibus nihil, quaerat aut dignissimos unde, cupiditate nobis modi. Sapiente doloremque sunt quaerat quae debitis minima.
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem id eligendi, repudiandae praesentium alias voluptatibus nihil, quaerat aut dignissimos unde, cupiditate nobis modi. Sapiente doloremque sunt quaerat quae debitis minima.
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem id eligendi, repudiandae praesentium alias voluptatibus nihil, quaerat aut dignissimos unde, cupiditate nobis modi. Sapiente doloremque sunt quaerat quae debitis minima.
                
                </p>
                <img src={AboutUsImage}/>
                <div className="mobile-about-us-heading"><b>About</b> Us</div>
            </div>
            
        </div>
    )
}

export default About