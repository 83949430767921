import React, { useMemo } from "react"
import PropTypes from "prop-types"
import Input from "./Input"
import useOTP from "./useOTP"

const OtpInput = ({
  OTPLength,
  disabled,
  autoFocus,
  value = "",
  onChange,
  otpType,
  secure,
  className
}) => {
  const {
    activeInput,
    getOtpValue,
    handleOnChange,
    handleOnKeyDown,
    handelOnInput,
    handleOnPaste,
    onInputFocus
  } = useOTP({
    autoFocus,
    value,
    otpType,
    onChange,
    OTPLength,
  })

  // Needs to be memorized
  const renderInputs = useMemo(() => {
    const otp = getOtpValue()
    const inputs = []

    for (let index = 0; index < OTPLength; index++) {
      inputs.push(
        <Input
          key={index}
          focus={activeInput === index}
          value={otp[index]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={handelOnInput}
          onPaste={handleOnPaste}
          onInputFocus={onInputFocus}
          index={index}
          disabled={disabled}
          autoFocus={autoFocus}
          secure={secure}
          data-testid="input"
          otpType={otpType}
        />
      )
    }

    return inputs
  }, [
    getOtpValue,
    OTPLength,
    activeInput,
    handleOnChange,
    handleOnKeyDown,
    handelOnInput,
    handleOnPaste,
    onInputFocus,
    disabled,
    autoFocus,
    secure
  ])

  return (
    <div
      className={`otp-input${className ? ` ${className}` : ''}`}
      data-testid="otp-input-root"
    >
      {renderInputs}
    </div>
  )
}

OtpInput.propTypes = {
  className: PropTypes.string,
  OTPLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  secure: PropTypes.bool,
  otpType: PropTypes.oneOf(["number", "alpha", "alphanumeric", "any"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

OtpInput.defaultProps = {
  className: "",
  OTPLength: 4,
  onChange: () => { },
  disabled: false,
  secure: false,
  autoFocus: false,
  value: "",
  otpType: "any"
}

export default OtpInput