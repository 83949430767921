import React, { useState, useRef } from 'react'
import { FAQ_Home } from '../../../core/faqConstant'
import { Accordion } from '@material-ui/core'
import { AccordionSummary } from '@material-ui/core'
import { AccordionDetails } from '@material-ui/core'
import './style.scss'
import AddExpandIcon from '../../../assets/icon/addExpandIcon.png'
import SubExpandIcon from '../../../assets/icon/subExpandIcon.png'


const FAQComponent = (device) => {
  const [pannel, setPannel] = useState('')

  const currentEl = useRef();

  const togglePannel = pannelName => {

    if (pannelName === pannel)
      setPannel('')
    else
      setPannel(pannelName)

      const yPositionBody = document.getElementsByTagName('body')[0].getBoundingClientRect()
      const yPosition = currentEl.current.getBoundingClientRect()
    const subTop = device === "desktop"?400:500
        window.scrollTo({
            top: yPosition.top-yPositionBody.top-subTop,
            behavior: "smooth",
          });
  }

  return (
    <section className="faqSection">
              <h2>FAQS</h2>
              <div className="accordion faqNewBikeContainer" id="accordionInspectionOverview">
                {
                  FAQ_Home.map(faq =>
                    <Accordion
                      expanded={pannel === faq.id}
                      onChange={() => togglePannel(faq.id)}
                      classes={{ expanded: 'credr-pannel' }}
                      key={faq.id}
                      ref={currentEl}
                    >
                      <AccordionSummary
                        expandIcon={<img src={pannel === faq.id ? SubExpandIcon:AddExpandIcon} alt="Expand" />}
                        id={faq.id}
                      >
                        {faq.question}
                      </AccordionSummary>
                      <AccordionDetails
                        // style={{ backgroundColor: '#f7f7f7', padding: '10px 24px', display:'block' }}
                      >
                        <div>{faq.answer}</div>
                    
                        {
                          faq.items && <ul>
                            {
                              faq.items.map((item,i) =>{
                                return (
                                  <li key={i+149}>{item}</li>
                                )
                              })
                            }
                          </ul>
                        }
                      </AccordionDetails>
                    </Accordion>
                  )
                }
              </div>
    </section>
  )
}

export default FAQComponent