import React from "react";
import './style.scss'
import StarFull from '../../../assets/icon/StarFull.svg'
import StarHalf from '../../../assets/icon/StarHalf.svg'
import StarEmpty from '../../../assets/icon/StarEmpty.svg'

const CustomerFeedBack = ({ratingData}) =>  {

  
    return (
      <div className="customerFeedBack">
                   <div className="col-sm-12">
              <h2 className="font-bold text-center sectionCustomMarginTop" >Customer Testimonials</h2>
              <div className="ratingCardConatainer">
                {
                  ratingData.map(item=>{
                    return(
                      <div className="ratingCardMain" key={item.customerName}>
                          <div className="ratingCard_customerProfile">
                            <img src={item.customerPicture} />
                            <div className="ratingCard_customerInfo">
                              <span>{item.customerName}</span>
                              <span>{"cheif Manager"}</span>
                            </div>
                          </div>
                          <p>{item.customerReview}</p>
                          <span style={{alignSelf:'flex-end', marginTop:'20px '}}>
                                {
                                [1,2,3,4,5].map(num => {
                                  if(num<=item.customerRating) return <img className="starImg" key={num} src={StarFull} />
                                  else if(num-.5 === item.customerRating)return <img className="starImg" key={num} src={StarHalf} />
                                  else return <img className="starImg" src={StarEmpty} key={num} /> 
                                })
                                }
                          </span>
                      </div>
                    )
                  })
                }
                </div>
              </div>
      </div>
    );
  }

  export default CustomerFeedBack