import React from 'react'
import  Lottie  from 'lottie-react';
import ComingSoonLottie from "../../assets/lottie/ComingSoon.json"
import BikeLottie from "../../assets/lottie/BikeRuuning.json"
import "./styles.scss"
const PriceCalculator = () => {
    
  return (
    <div className='bg-container-price-calculator'>
        <h2>Price Calulator</h2>
        
        <Lottie
            animationData={ComingSoonLottie}
            loop={true}
            autoplay={true}
            style={{ width: 300 }}
          />
          <Lottie
            animationData={BikeLottie}
            loop={true}
            autoplay={true}
            style={{ width: 400}}
          />
    </div>
  )
}





export default PriceCalculator