import React from "react"
import PropTypes from "prop-types"
import useResendOTP from "./useResendOtp"

function ResendOTP({ renderTime, renderButton, renderSkipButton, className, renderOtpTime, ...props }) {
  const { remainingTime, handelResendClick, handelSkipClick } = useResendOTP(props)
  return (
    <div
      className={className || ""}
      data-testid="otp-resend-root"
    >
      {
        renderTime ? (
          renderTime(remainingTime)
        ) : (
            <span>{remainingTime} sec</span>
          )
      }
      {
        renderOtpTime &&
        renderOtpTime({
          disabled: remainingTime !== 0,
          onClick: handelResendClick,
          remainingTime
        }) 
      }
      {
        renderButton ?
          renderButton({
            disabled: remainingTime !== 0,
            onClick: handelResendClick,
            remainingTime
          }) :
          <button disabled={remainingTime !== 0} onClick={handelResendClick}>
            Resend OTP
          </button>
      }
    </div>
  )
}

ResendOTP.defaultProps = {
  maxTime: 30,
  timeInterval: 1000
}

ResendOTP.propTypes = {
  onTimerComplete: PropTypes.func,
  onResendClick: PropTypes.func,
  renderTime: PropTypes.func,
  renderButton: PropTypes.func,
  maxTime: PropTypes.number,
  timeInterval: PropTypes.number,
  className: PropTypes.string
}

export default ResendOTP