import React from "react";
import Image from '../../assets/icon/contact_us.svg'
import PhoneIcon from '../../assets/icon/phone.svg'
import MailIcon from '../../assets/icon/email.svg'
import MarkerRed from '../../assets/icon/map-markerR.svg'
import './style.scss';
import ContactInfo from "./ContactInfo";

const contactData = [
    {
        image:PhoneIcon,
        label:"Phone Number",
        info:"88044 04444"
    },
    {
        image:MailIcon,
        label:"E-mail Address",
        info:"cs@exchangemybike.com"
    },
    {
        image:MarkerRed,
        label:"Location",
        info:"No. 212/A, 2nd floor, 1st Main Rd, 2nd Stage, Domlur, Bengaluru, Karnataka 560071"
    }
]

const Contact = () => { 
    
    return(
        <main className="mainContainer contactPage">
            <div className="imgContainer">
                <img src={Image} alt="contact us Cover" />
            </div>
            <div className="contactUsDetails">
            <div className="detialsContainer">
                <span>Contact Us</span>
                <h1>Get in touch with us</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
            </div>
            <div className="contactInfoContainer">
                {
                    contactData.map((data, i) =>{
                        return(
                            <ContactInfo key={i} img ={data.image} label={data.label} info={data.info}/>
                            )
                    })
                }
            </div>
            </div>
        </main>
    )
}

export default Contact