import React,{useState,useRef} from 'react'
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText'
import {
    alpha,
    withStyles,
    makeStyles,
  } from '@material-ui/core/styles';
  import InputBase from '@material-ui/core/InputBase';
  import './styles.scss'


  import Select from 'react-select';
  import "./styles.scss"

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: "#F6F7F9",
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      minWidth:"30vw",
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&::placeholder': {
            fontSize: '0.7em', // Change placeholder font size
        },
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))((props)=><InputBase {...props}/>);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      marginRight: theme.spacing(2),
      minHeight:"5em",
      padding: theme.spacing(1),
      paddingTop:"0px",
      paddingBottom:"0px",
      minHeight:"5.2em",
      width:"50%",
      [theme.breakpoints.down('xs')]: { // xs corresponds to max-width: 599.95px
        width: "100%",
        padding: theme.spacing(1),
        paddingTop:"0px",
        paddingBottom:"0px",
        minHeight:"6.2em",
      },
    },
    redtext:{
        color:"red",
        fontSize:"0.5em"
    },
    labelColor:{
        color:"black",
    }
  }));

const TextInput = ({label,onChange,placeholder,value, type}) => {
    const [touched, setTouched] = useState(false);
    const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
    const classes = useStyles();
    const handleBlur = () => {
        setTouched(true);
    };
  return (
    <FormControl className={classes.margin}>
                    <div className={classes.labelColor}>
                    <InputLabel>
                    {label}
                    </InputLabel>
                    <BootstrapInput   
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    id="bootstrap-input" 
                    onBlur={handleBlur}
                    required
                    type = {type}
                    />
                    </div>
                    <Select
                      className="basic-single custom-select"
                      classNamePrefix="select"
                      // defaultValue={colourOptions[0]}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isClearable={isClearable}
                      isRtl={isRtl}
                      isSearchable={isSearchable}
                      name="color"
                      // options={colourOptions}
                      onBlur={handleBlur}
                    />
                {touched && !value.length && <FormHelperText className={classes.redtext}>* Please Enter {label}</FormHelperText>}
                </FormControl>
                
  )
}

export default TextInput