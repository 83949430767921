import {
    createBrowserRouter,
  } from "react-router-dom"
  import React from "react";
import Home from "../Components/Home/Home.js";
import About from "../Components/About/About.js";

// import Bio from "./Bio";
import Contact from "../Components/Contact/Contact.js";
import App from "../App";
import Exchange from "../Components/Exchange/Exchange.js";
import Thankyou from "../Components/Thankyou/Thankyou.js";
import PriceCalculator from "../Components/PriceCalculator/PriceCalculator.js";


  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children:[
        {
            path: "/",
            element: <Home />,
          },
          {
            path: "/about",
            element: <About hello = {'hello'} />,
          },
          {
            path: "/exchange",
            element: <Exchange hello = {'hello'} />,
          },
          {
            path: "/priceCalculator",
            element: <PriceCalculator />,
          },
          {
            path: "/contact",
            element: <Contact />,
          },
          
          {
            path: "/thankyou",
            element: <Thankyou />,
          },
      ]
    },
  ]);

  export default appRouter