export const FAQ_Home = [
  {
    question: 'What is the process for buying new bikes online from CredR?',
    answer: "Buying a brand new bike from CredR is hassle-free. Visit our website and browse through an array of new bikes. Assess each model's specs, features, and price and select the desired one. Upon selecting the desired bike, enter the details that are requested. Our agent will reach out to you and guide you through the buying process.",
    id: 'collapseOne'
  },
  {
    question: 'What are the latest bikes launched?',
    answer: 'Choose a new model two wheeler from a range of newly launched bikes like Kawasaki Z900, Kawasaki Ninja 500, Hero Mavrick 400, and Triumph Scrambler 1200.',
    id: 'collapseTwo'
  },
  // {
  //   question: 'What are the parameters for rejecting a two-wheeler?',
  //   answer: `The following observations in the inspection could lead to rejection of two-wheelers:`,
  //   items: [
  //     `RC, Test Ride - Not available`,
  //     `Loan - Ongoing/Not available`,
  //     `Engine, transmission - Needs repair/replacement`,
  //     `Engine Oil - Leakage`,
  //     `Silencer - Black/blue smoke emission`,
  //     `Distance driven - More than 70,000 km`,
  //     `Metre condition - Tampered`
  //   ],
  //   id: 'collapseThree'
  // },
  {
    question: 'What are the upcoming bikes in India?',
    answer: 'The latest bike in India are KTM 390 Adventure, Royal Enfield Classic 650, Yamaha MT-09, Honda CRF300L, and Royal Enfield Himalayan 650.',
    id: 'collapseFour'
  },
  {
    question: 'What are the popular bike brands in India?',
    answer: 'Some of the popular bike brands in India are Royal Enfield, Kawasaki, Bajaj, Splendor, and Honda SP.',
    id: 'collapseFive'
  },
  {
    question: 'Which bikes are the best to buy in 2024?',
    answer: 'Pick from feature-rich bikes like Yamaha MT 15, Royal Enfield Classic 350, Hero Splendor Plus, and Yamaha R15 V4 in 2024. Browse through our website to check the all new bike prices to find something that suits your budget.',
    id: 'collapseSix'
  },
]