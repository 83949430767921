import React from "react"
import { NavLink  } from "react-router-dom"
import Logo from '../../assets/icon/mainLogo.svg'
import CityIcon from '../../assets/icon/city-outline.svg'
import './style.scss';

const Header = () =>{
    return(
        <header>
            <div className="logoSearchContainer">
                <img className="logo" src={Logo} alt="Company Logo" />
                <div className="selectCityBox">
                <select >
                    <option>Select City</option>
                    <option>Bangalore</option>
                    <option>Pune</option>
                    <option>Mumbai</option>
                    <option>Mysore</option>
                    <option>Nashik</option>
                    <option>Chikkaballapur</option>
                </select>
                <img src={CityIcon} alt="" />
                </div>
              
            </div>
            <div className="navContainer">
            <nav>

                <NavLink to="/" className={({ isActive }) => isActive ? "navLinks active" : "navLinks"}>Home</NavLink >
                <NavLink to="/exchange" className={({ isActive }) => isActive ? "navLinks active" : "navLinks"}>Exchange</NavLink >
                {/* <NavLink to="/some" className={({ isActive }) => isActive ? "navLinks active" : "navLinks"}>Resale Calculator</NavLink > */}
                <NavLink to="/about" className={({ isActive }) => isActive ? "navLinks active" : "navLinks"}>About Us</NavLink >
                <NavLink to="/contact" className={({ isActive }) => isActive ? "navLinks active" : "navLinks"}>Contact us</NavLink >
                </nav>
            </div>
        
        </header>
    )
}

export default Header