import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

const Input = ({
  focus,
  autoFocus,
  disabled,
  value,
  onInputFocus,
  index,
  secure,
  inputStyles,
  className,
  otpType,
  ...rest
}) => {
  const input = useRef(null)
  const componentMounted = useRef(false)

  useEffect(() => {
    if (autoFocus && focus) {
      input.current.focus()
    }
  }, [])

  useEffect(() => {
    if (componentMounted.current && focus) {
      input.current.focus()
    }
    componentMounted.current = true
  }, [focus])

  const handelInputFocus = event => onInputFocus(index, event)
  let inputType = "text"
  if (secure) {
    inputType = "password"
  } else if (otpType === "number") {
    inputType = "tel"
  }
  return (
    <input
      pattern="\d*" 
      type="tel"
      maxLength="1"
      ref={input}
      disabled={disabled}
      onFocus={handelInputFocus}
      value={value || ""}
      className={`otp-input-box ${className ? ` ${className}` : ''}`}
      {...rest}
    />
  )
}

Input.propTypes = {
  focus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  numInputs: PropTypes.number,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  secure: PropTypes.bool,
  otpType: PropTypes.oneOf(["number", "alpha", "alphanumeric", "any"])
}

export default React.memo(Input)