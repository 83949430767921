import React, { useState } from "react"
import { useNavigate } from 'react-router-dom';
import TextInput from "../SharedComponent/InputComponent/TextInput"
import "./styles.scss"
import OTPBox from "./OTPBox";



const Exchange = () => {
    const [oldBikeForm,setOldBikeForm]=useState(true)
    const [userDetailsForm,setUserDetailsForm]=useState(false)
    const [newBikeForm,setNewBikeForm]=useState(false)

    const [city,setCity]=useState("")
    const [make,setMake]=useState("")
    const [model,setModel]=useState("")
    const [variant,setVariant]=useState("")
    const [year,setYear]=useState("")
    const [rtoCode,setRtoCode]=useState("")

    const [newMake,setNewMake]=useState("")
    const [newModel,setNewModel]=useState("")
    const [newVariant,setNewVariant]=useState("")
    const [newYear,setNewYear]=useState("")
    const navigate = useNavigate();
    const onChangeValue=(e,type)=>{
        switch (type) {
            case "city":
                setCity(e.target.value)
                break;
            case "make":
                setMake(e.target.value)
                break;
            case "model":
                setModel(e.target.value)
                break;
            case "variant":
                setVariant(e.target.value)
                break;
            case "year":
                setYear(e.target.value)
                break;
            case "rto Code":
                setRtoCode(e.target.value)
                break;
            case "newMake":
                setNewMake(e.target.value)
                break;
            case "newModel":
                setNewModel(e.target.value)
                break;
            case "newVariant":
                setNewVariant(e.target.value)
                break;
            case "newYear":
                setNewYear(e.target.value)
                break;
        
            default:
                break;
        }
    }


    const onClickExchange=()=>{
    setOldBikeForm(false)
    setUserDetailsForm(true)
    
    }
    const onClickVerify=()=>{
        setUserDetailsForm(false)
        setNewBikeForm(true)
        
        }
    const onClickSubmit=()=>{
        navigate('/thankyou', { state: { someData: 'value' } });
    }

  return (
    <div className="bg-container-inputForm">
        <h3>Enter Used Bike Details</h3>
        {oldBikeForm && <div className="container-card-input">
            <h5>Do you have used bike to exchange ?</h5>
            <p>Please enter your Used Bike Details</p>
            <div>
                <TextInput
                label="City"
                placeholder="City"
                value={city}
                onChange={(e)=>onChangeValue(e,"city")}
                />
                <div className="display-two">
                    <TextInput
                    label="Make"
                    placeholder="Make"
                    value={make}
                    onChange={(e)=>onChangeValue(e,"make")}
                    />
                    <TextInput
                    label="Model"
                    placeholder="Model"
                    value={model}
                    onChange={(e)=>onChangeValue(e,"model")}
                    />
                </div>
                <div className="display-two">
                    <TextInput
                    label="Variant"
                    placeholder="Variant"
                    value={variant}
                    onChange={(e)=>onChangeValue(e,"variant")}
                    />
                        <TextInput
                    label="Year"
                    placeholder="Year"
                    value={year}
                    onChange={(e)=>onChangeValue(e,"year")}
                    />
                </div>
                <TextInput
                label="RTO Code"
                placeholder="RTO Code"
                value={rtoCode}
                onChange={(e)=>onChangeValue(e,"rtoCode")}
                />
            <div>
                    <button onClick={onClickExchange} className="exchange-button">Exchange Now</button>
                
            </div>
            

            </div>
            
        </div>}

        {userDetailsForm && <div className="container-card-input">
            <div className="container-user-details">
            <h5>Enter your Deatils</h5>
            <p>Today is a new day. It's your day. You shape it. <br/>Sign in to start managing your projects.</p>
            
            <div>
                <TextInput
                label="Your Name"
                placeholder="Enter Your Name"
                value={make}
                onChange={(e)=>onChangeValue(e,"make")}
                />
            </div>
             <div>
                <TextInput
                label="Phone Number"
                placeholder="Enter Your Phone Number"
                value={model}
                type='number'
                onChange={(e)=>onChangeValue(e,"model")}
                />
            </div>
            <OTPBox autoRead />
            <div>
                    <button onClick={onClickVerify} className="exchange-button verifyButton">Verify</button>
                
            </div>
            </div>
            
        </div>}

        {newBikeForm && <div className="container-card-input">
            <h5>Which New Bike are you Looking for?</h5>
            <p>Please enter your New Bike Details</p>
            <div>
                <div>
                    <TextInput
                    label="OEM Make"
                    placeholder="Enter Make"
                    value={newMake}
                    onChange={(e)=>onChangeValue(e,"newMake")}
                    />
                    <TextInput
                    label="OEM Model"
                    placeholder="OEM Model"
                    value={newModel}
                    onChange={(e)=>onChangeValue(e,"newModel")}
                    />
                </div>
                <div>
                    <TextInput
                    label="OEM Variant"
                    placeholder="OEM Variant"
                    value={newVariant}
                    onChange={(e)=>onChangeValue(e,"newVariant")}
                    />
                    <TextInput
                    label="Year"
                    placeholder="Year"
                    value={newYear}
                    onChange={(e)=>onChangeValue(e,"newYear")}
                    />
                </div>
            </div>
        
            <div>
                    <button onClick={onClickSubmit} className="exchange-button">Submit</button>
                
            </div>
            

        </div>}
    </div>
  )
}

export default Exchange