import React from "react";
import {isMobile} from "react-device-detect"
import CustomerFeedBack from "../SharedComponent/CustomerFeedBack";
import profileCust from '../../assets/icon//profileCust.svg'
import profileCust2 from '../../assets/icon/profileCust2.svg'
import profileCust3 from '../../assets/icon/profileCust3.svg'
import FAQComponent from "../SharedComponent/FAQComponent";

import NavigatorHome from "./NavigatorHome"
import DesktopBannerOne from "../../assets/icon/Banner1.svg"
import MobileBannerOne from "../../assets/icon/MobileBanner1.svg"
import ExchangeCarousel from "./ExchangeCarousel";
const ratingData = [
    {
        customerName: 'Alok Sarangi',
        customerRating: 4.5,
        customerPicture: profileCust,
        customerReview: "I recently bought a bike from CredR, and it was a fantastic experience. All employees were supportive & well-mannered people."
    },
    {
        customerName: 'Kirtesh Patil',
        customerRating: 2.5,
        customerPicture: profileCust2,
        customerReview: "My experience purchasing from CredR was outstanding and smooth throughout the process, primarily due to the exceptional support and sales executive."
    },
    {
        customerName: 'Priyanshu Agrawal',
        customerRating: 3.5,
        customerPicture: profileCust3,
        customerReview: "Very nice experience in CredR Bellandur Showroom. The buying process was smooth, and the paperwork was handled efficiently."
    }
]


const Home = () => { 
    const bannerData = [DesktopBannerOne,DesktopBannerOne]
    const bannerDataMobile = [MobileBannerOne,MobileBannerOne,MobileBannerOne]
    return(
        <div className="homePage">
            <ExchangeCarousel banner={isMobile? bannerDataMobile:bannerData  } />
            <NavigatorHome/>
            <CustomerFeedBack ratingData ={ratingData} />
            <FAQComponent />
            
        </div>
    )
}

export default Home